<template>
  <div class="w-100">
    <div class="title-actions">
      <h5 class="title">{{ division.name }} - {{ $t('customButtons') }}</h5>
      <div class="btns">
        <b-button @click="add" variant="info">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered hover :items="buttons" :fields="columns">
      <div slot="order" slot-scope="row">
        {{ row.index + 1 }}
      </div>
      <div slot="link" slot-scope="row">
        <a :href="row.value" target="_blank">{{ row.value }}</a>
      </div>
      <div slot="showin" slot-scope="row">
        <span v-if="row.value === '_SELF'">{{ $t('selfLink') }}</span>
        <span v-else>{{ $t('blankLink') }}</span>
      </div>
      <div slot="actions" slot-scope="row" class="text-center">
        <b-button variant="info" @click="openModalEdit(row.item)" class="mx-2">
          <i class="fa fa-pencil px-1"></i>
        </b-button>
        <b-button variant="danger" @click="openModalDestroy(row.item)" class="mx-2">
          <i class="fa fa-trash px-2"></i>
        </b-button>
      </div>
    </b-table>
    <!-- Modal eliminar todos los players del team -->
    <b-modal :title="$t('delete')" headerBgVariant="danger" headerTextVariant="light" id="modalCustomButtonsDestroy">
      <h6>{{ $t('confirmTextDelete') }}</h6>
      <h6>{{ button.label }}</h6>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalCustomButtonsDestroy')">{{ $t('not') }}</b-button>
        <b-button type="button" @click="destroy" variant="primary">{{ $t('yes') }}</b-button>
      </template>
    </b-modal>
    <!-- Modal Agregar quitar dinero -->
    <b-form @submit.prevent="save">
      <b-modal id="modalCustomButtons" :title="title">
        <div class="form-group">
          <label for="label">{{ $t('label') }}</label>
          <b-form-input v-validate="'required|max:25'" name="label" id="label" :state="validateState('label')" v-model="button.label"/>
          <span class="error-inputs">{{ errors.first('label') }}</span>
        </div>
        <div class="form-group">
          <label for="title">{{ $t('title') }}</label>
          <b-form-input v-validate="'required|max:50'" name="title" id="title" :state="validateState('title')" v-model="button.title"/>
          <span class="error-inputs">{{ errors.first('title') }}</span>
        </div>
        <div class="form-group">
          <label for="url">{{ $t('url') }}</label>
          <b-form-input v-validate="'required|url|max:150'" name="url" id="url" :state="validateState('url')" v-model="button.link"/>
          <span class="error-inputs">{{ errors.first('url') }}</span>
        </div>
        <div class="form-group">
          <label for="showin">{{ $t('showIn') }}</label>
          <select name="showin" id="showin" class="form-control" v-validate="'required'" :state="validateState('showin')" v-model="button.showin">
            <option value="_SELF">{{ $t('selfLink') }}</option>
            <option value="_BLANK">{{ $t('blankLink') }}</option>
          </select>
          <span class="error-inputs">{{ errors.first('showin') }}</span>
        </div>
        <template slot="modal-footer">
          <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalCustomButtons')">{{ $t('cancel') }}</b-button>
          <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      columns: [
        { key: 'order', label: '#', sortable: false },
        { key: 'label', label: this.$t('label'), sortable: false },
        { key: 'title', label: this.$t('title'), sortable: false },
        { key: 'showin', label: this.$t('showIn'), sortable: false },
        { key: 'link', label: 'Url', sortable: false },
        { key: 'actions', label: '', sortable: false }
      ],
      buttons: [],
      button: {},
      title: '',
      tournamentId: '',
      action: 'S'
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'tournament',
      'division'
    ])
  },
  mounted() {
    this.tournamentId = this.$route.params.tournamentId
    if (!this.division.name) {
      const divisionId = this.$route.params.divisionId
      this.$router.push({ name: 'DivisionBack', params: { lang: this.lang, console: this.console, tournamentId: this.tournamentId, divisionId } })
      return
    }
    this.changeTitleBar(this.division.name + ' - ' + this.$t('customButtons'))
    this.fetchData()
  },
  methods: {
    openModalDestroy (button) {
      this.button = button
      this.$root.$emit('bv::show::modal', 'modalCustomButtonsDestroy')
    },
    openModalEdit (button) {
      this.button = JSON.parse(JSON.stringify(button))
      this.title = this.$t('edit')
      this.action = 'U'
      this.$root.$emit('bv::show::modal', 'modalCustomButtons')
    },
    add () {
      this.button = {}
      this.title = this.$t('create')
      this.action = 'S'
      this.$root.$emit('bv::show::modal', 'modalCustomButtons')
    },
    destroy () {
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/division/${divisionId}/custom-buttons/${this.button.id}/delete`
      this.$axios.delete(path).then(() => {
        this.buttons = this.buttons.filter(but => {
          return but.id !== this.button.id
        })
        this.$root.$emit('bv::hide::modal', 'modalCustomButtonsDestroy')
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = this.button
          const divisionId = this.$route.params.divisionId
          if (this.action === 'S') {
            const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/division/${divisionId}/custom-buttons/store`
            this.$axios.post(path, payload).then(response => {
              this.buttons.unshift(response.data.data)
              this.$root.$emit('bv::hide::modal', 'modalCustomButtons')
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
            })
          } else {
            const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/division/${divisionId}/custom-buttons/${this.button.id}/update`
            this.$axios.put(path, payload).then(response => {
              const data = response.data.data
              this.buttons = this.buttons.map(but => {
                if (but.id === data.id) {
                  but.link = data.link,
                  but.title = data.title
                  but.showin = data.showin
                  but.label = data.label
                }
                return but
              })
              this.$root.$emit('bv::hide::modal', 'modalCustomButtons')
              this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
            })
          }
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    fetchData () {
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournament/${this.tournamentId}/division/${divisionId}/custom-buttons`
      this.$axios.get(path).then((response) => {
        this.buttons = response.data.data
      })
    }
  }
}
</script>
